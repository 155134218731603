import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Content, PictureFrame } from '../styles/common'
import Layout from '../components/Layout'


const IndexPage = () => {
    return (
        <Layout pageTitle='странный уголок интернета'>
            <Content>
                <PictureFrame>
                    <StaticImage src='../images/portrait.jpg' alt='portrait' />
                </PictureFrame>
                <p>Yep. That's me. You're probably wondering how I ended up here. Well, the truth is that isn't a real, living dinosaur. Those have been extinct for a very long time.</p>

                <h3>What I Do</h3>
                <p>I am a full stack developer, tinkerer, cyclist, and hobbyist musician. I've been working in the React space for a number of years, but I have experience with a number of other languages and technologies. I have a thing for 90s hardtail mountain bikes. Something about the 26 inch wheels and relaxed road bike frame geometry really makes for a great commuter bike.</p>
            </Content>
        </Layout>
    )
}

export default IndexPage
